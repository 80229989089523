import React from 'react';
import Puzzle6Event from '../../components/PageEvents/Puzzle6Event';
import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'page_event',
        component: <Puzzle6Event></Puzzle6Event>
    },
}

function Puzzle6() {
    let badgeId = 6;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle6;