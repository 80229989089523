export const getTimesRefreshed = () => {
    initializeEventsIfNotExists();

    return JSON.parse(window.localStorage.getItem('events')).refresh;
}

export const incrementRefreshedAmount = () => {
    initializeEventsIfNotExists();

    let events = JSON.parse(window.localStorage.getItem('events'));
    events.refresh += 1;

    window.localStorage.setItem('events', JSON.stringify(events));
}

export const initializeEventsIfNotExists = () => {
    if (!window.localStorage.getItem('events')) {
        let events = {
            refresh: 0
        }

        window.localStorage.setItem('events', JSON.stringify(events));
    }
}