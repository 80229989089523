import React from 'react';

import Puzzle1Event from '../../components/PageEvents/Puzzle1Event';
import EventParser from '../../components/EventParser';


let events = {
    0: {
        name: 'page_event',
        component: <Puzzle1Event></Puzzle1Event>,
    }
}

function Puzzle1() {
    let badgeId = 1;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle1;