import React, {useState, useContext} from 'react';
import { EventContext } from '../../context/EventContext';

import { getRandomInt } from '../../helpers/Math';

let gameOverDialogue = [
    "You aren't ready.",
    "This test wasn't meant for you.",
    "Quit now.",
    "You are a failure.",
    "Quit.",
    "You will always be a failure.",
    "You are not smart enough.",
    "You are not clever enough.",
    "You are not skilled enough.",
    "You cannot overcome this.",
    "You will always be a quitter."
]

function Puzzle7Event() {
    const setEventCompleted = useContext(EventContext);
    const [shownNumbers, setShownNumbers] = useState([]);
    const [currentNumber, setCurrentNumber] = useState(null);
    const [answersStatus, setAnswersStatus] = useState({
        numCorrect: 0,
        numIncorrect: 0
    });

    const [shouldDisplayGameOverText, setShouldDisplayGameOverText] = useState(false);
    const [gameOverText, setGameOverText] = useState(null);

    const [gameStatus, setGameStatus] = useState({
        step: 'pre-init',
    });

    const sendSuccess = () => {
        setEventCompleted();
    }

    const displayGameOverText = () => {
        let text = gameOverDialogue[getRandomInt(0, gameOverDialogue.length)];

        if (text) {
            setGameOverText(text);
            setShouldDisplayGameOverText(true);

            setTimeout(() => {
                setShouldDisplayGameOverText(false);
            }, 4000);
        }
    }

    const restartGame = () => {
        setShownNumbers([]);
        setAnswersStatus({
            numCorrect: 0,
            numIncorrect: 0
        });

        displayGameOverText();

        startGameRound();
    }

    const getAndSetRoundNumber = () => {
        let number = getRandomInt(15, 61);
        setCurrentNumber(number);
    }

    const incrementNumCorrect = () => {
        let _answersStatus = {...answersStatus};
        _answersStatus.numCorrect += 1;

        setAnswersStatus(_answersStatus);

    }

    const incrementNumIncorrect = () => {
        let _answersStatus = {...answersStatus};
        _answersStatus.numIncorrect += 1;

        setAnswersStatus(_answersStatus);
    }

    const initGameRound = () => {
        getAndSetRoundNumber();

        _setGameStatus('start');
    }

    const startGameRound = () => {
        getAndSetRoundNumber();
    }

    const _setGameStatus = (newStatus) => {
        let _gameStatus = {...gameStatus};
        _gameStatus.step = newStatus;
        setGameStatus(_gameStatus);
    }

    const checkAnswer = (answer) => {
        let correct = false;
        
        if (shownNumbers.includes(currentNumber)) {
            if (answer === 'yes') {
                correct = true
            } else if (answer === 'no') {
                correct = false;
            }
        } else {
            if (answer === 'yes') {
                correct = false;
            } else if (answer === 'no') {
                correct = true;
            }
        }

        if (correct) {
            if (answersStatus.numCorrect + 1 >= 28) {
                sendSuccess();
                return;
            }

            incrementNumCorrect();
        } else {
            if (answersStatus.numIncorrect + 1 >= 3) {
                restartGame();
                return;
            } else {
                incrementNumIncorrect();
            }
            
        }

        if (!shownNumbers.includes(currentNumber)) {
            let _shownNumbers = shownNumbers.splice(0);
            _shownNumbers.push(currentNumber);
            setShownNumbers(_shownNumbers);
        }

        startGameRound();
    }

    const gameDisplay = () => {
        let component = null;

        switch (gameStatus.step) {
            case 'pre-init': 
                component = (
                        <div id='intro'>
                        <p>If you won't give up, I'm going to have to kick up the difficulty a few notches.</p>
                        <p>I won't let you go any further.</p>

                        <button className='btn-black-border btn mt-3' onClick={() => _setGameStatus('init')}>Start</button>
                    </div>
                );
                break;

            case 'init': 
                component = (
                    <div id='game'>
                        <p>
                            Here's how this works. I will show you a number, and you will tell me whether I have shown you that number already or not. 
                            Simple, right?
                        </p>

                        <p className='mt-3'>
                            All you need to do is get 28 correct and make sure you don't get 3 incorrect.
                            In a situation where you get 3 incorrect, I will restart the game.
                        </p>

                        <p className='mt-3'>Understood?</p>
                        <button className='btn-black-border btn mt-3' onClick={() => initGameRound()}>Let's start already</button>
                    </div>
                );
                break;

            case 'start': 
                component = (
                    <div id='game' className='text-center'>
                        <p>The number is</p>
                        <p className='mb-4 mt-4 text-2xl font-bold'>{currentNumber}</p>

                        <p>Have I shown you this number before?</p>

                        <div className='mt-5 flex gap-5 justify-center'>
                            <button onClick={() => checkAnswer('yes')} className='btn-black-border btn'>Yes</button>
                            <button onClick={() => checkAnswer('no')} className='btn-black-border btn'>No</button>
                        </div>

                        <div className='mt-5'>
                            <p><span className='text-green-600'>{answersStatus.numCorrect}</span> / <span className='text-red-600'>{answersStatus.numIncorrect}</span></p>
                        </div>

                        <p className='text-3xl h-14 mt-10'>
                            {shouldDisplayGameOverText === true &&
                                <span className='animate-fadein text-red-600'>{gameOverText}</span>
                            }
                        </p>
                    </div>
                );
                break;
            default:
                break;
        }


        return component;
    }

    return (
        <div className='h-screen flex items-center justify-center m-auto max-w-screen-sm px-8 py-6'>
            {gameDisplay()}
        </div>
    );
}

export default Puzzle7Event;