import React, {useEffect, useState, useContext} from 'react';
import useInterval from '../../hooks/useInterval';
import { EventContext } from '../../context/EventContext';

import { getRandomInt } from '../../helpers/Math';

function Puzzle6Event(props) {
    let movementDelayDefault = 630;
    let flagQuota = 24;
    const screenTickRateDefault = 8000;
    let screenTickDecrement = 210;

    if (props.flagQuota) {
        flagQuota = props.flagQuota;
    } 

    if (props.screenTickDecrement) {
        screenTickDecrement = props.screenTickDecrement;
    }

    if (props.movementDelayDefault) {
        movementDelayDefault = props.movementDelayDefault;
    }

    const setEventCompleted = useContext(EventContext);
    const [playerPosition, setPlayerPosition] = useState({
        x: 0,
        y: 0
    });

    const [movement, setMovement] = useState({
        delay: movementDelayDefault,
        position: {
            x: 0,
            y: 0
        }
    });

    const [playerIsMoving, setPlayerIsMoving] = useState(false);

    const [flagPosition, setFlagPosition] = useState({
        x: 50,
        y: 50
    });

    const [flagIsCaptured, setFlagIsCaptured] = useState(false);
    const [flagsCapturedCount, setFlagsCaptured] = useState(0);

    const [movementAllowed, setMovementAllowed] = useState(true);

    const [gameTick, setGameTick] = useState(0);
    const [screenTickRate, setScreenTickRate] = useState(8000);

    useInterval(() => {
        setGameTick(gameTick + 50);

        if (gameTick >= screenTickRate) {
            setMovementAllowed((movementAllowed) => !movementAllowed);
            setGameTick(0);

            setScreenTickRate(screenTickRateDefault - (flagsCapturedCount * screenTickDecrement));
        }

        if (!movementAllowed && playerIsMoving) {
            restartGame();
        }
    }, 50);

    useEffect(() => {
        randomizeFlagPosition();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (checkFlagIsCaptured()) {
            setFlagIsCaptured(true);

            setTimeout(() => {
                handleFlagIsCaptured();
            }, movement.delay);
        }
    }, [playerPosition]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (flagsCapturedCount >= flagQuota) {
            sendSuccess();
        }
    }, [flagsCapturedCount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handlePlayerIsMoving();
        setPlayerPosition(movement.position);
    }, [movement]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const restartGame = () => {
        setFlagsCaptured(0);
        setPlayerPosition({
            x: 0,
            y: 0
        });

        setPlayerIsMoving(false);
    }

    const movePlayer = (e) => {
        if (!playerIsMoving) {
            let position = {
                x: (e.clientX / document.body.clientWidth) * 100,
                y: (e.clientY / document.body.clientHeight) * 100,
            };

            if (!movementAllowed) {
                position = {
                    x: 0,
                    y: 0
                }
            }
            
            let distance = Math.abs(playerPosition.x - position.x) + Math.abs(playerPosition.y - position.y);
            
            setMovement({
                delay: movementDelayDefault + distance * 12,
                position: position
            });
        }
    }

    const handlePlayerIsMoving = () => {
        setPlayerIsMoving(true);
        
        setTimeout(() => {
            setPlayerIsMoving(false);
        }, movement.delay);
    }

    const randomizeFlagPosition = () => {
        let _flagPosition = {...flagPosition};
        
        _flagPosition.x = getRandomInt(10, 90);
        _flagPosition.y = getRandomInt(16, 90);

        setFlagPosition(_flagPosition);
        setFlagIsCaptured(false);
    }

    const checkFlagIsCaptured = () => {
        if (!flagIsCaptured && (screenTickRate - gameTick) > movement.delay) {
            if (playerPosition.x - 2.8 < flagPosition.x && playerPosition.x + 2.8 > flagPosition.x) {
                if (playerPosition.y - 2.8 < flagPosition.y && playerPosition.y + 2.8 > flagPosition.y) {
                    return true;
                }
            }
        }

        return false;
    }

    const handleFlagIsCaptured = () => {
        setFlagsCaptured(flagsCapturedCount + 1);
        randomizeFlagPosition();
    }

    let extraScreenBg = movementAllowed === false ? 'bg-red-600' : 'bg-green-600';
    
    return (
        <div className='h-screen overflow-hidden'>
            <div onClick={(e) => movePlayer(e)} id='stage' className={'relative h-screen cursor-pointer ' + extraScreenBg}>
                <div style={{width: 15 + (flagsCapturedCount * 4), height: 15 + (flagsCapturedCount * 4), left: playerPosition.x + '%', top: playerPosition.y + '%', transitionTimingFunction: 'linear', transitionDuration: movement.delay + 'ms', transform: 'translate(-'+playerPosition.x+'%, -'+playerPosition.y+'%)'}} id='player' className='absolute bg-black rounded-full'></div>
                <div style={{left: flagPosition.x + '%', top: flagPosition.y + '%'}} className='w-3 h-3 bg-white absolute'></div>
            </div>

            <div id='controls' className='z-10'>
                <div className='top-transform'>
                    <p className='text-lg md:text-xl select-none text-white'>{flagsCapturedCount} of {flagQuota} flags captured!</p>
                </div>
            </div>
        </div>
    );
}

export default Puzzle6Event;