import React from 'react';
import Puzzle1Event from '../../components/PageEvents/Puzzle1Event';
import Puzzle6Event from '../../components/PageEvents/Puzzle6Event';
import Puzzle2Alternate from '../../components/PageEvents/Alternate/Puzzle2Alternate';
import Puzzle5Alternate from '../../components/PageEvents/Alternate/Puzzle5Alternate';
import Puzzle7Alternate from '../../components/PageEvents/Alternate/Puzzle7Alternate';
import Puzzle8Event from '../../components/PageEvents/Puzzle8Event';
import EventParser from '../../components/EventParser';


let events = {
    0: {
        name: 'page_event',
        component: <Puzzle8Event></Puzzle8Event>
    },
    1: {
        name: 'page_event',
        component: <Puzzle1Event min={100000000000} max={10000000000000} version='alternate'></Puzzle1Event>
    },
    2: {
        name: 'page_event',
        component: <Puzzle2Alternate squares={12} text='You are not strong enough.'></Puzzle2Alternate>
    },
    3: {
        name: 'page_event',
        component: <Puzzle1Event min={10000000000000} max={1000000000000000} version='alternate'></Puzzle1Event>
    },
    4: {
        name: 'page_event',
        component: <Puzzle6Event flagQuota={7} screenTickDecrement={830} movementDelayDefault={1310}></Puzzle6Event>
    },
    5: {
        name: 'page_event',
        component: <Puzzle2Alternate squares={16} text='YOU WILL FAIL.'></Puzzle2Alternate>
    },
    6: {
        name: 'page_event',
        component: <Puzzle1Event min={10000000000000000} max={100000000000000000000} version='alternate'></Puzzle1Event>
    },
    7: {
        name: 'page_event',
        component: <Puzzle7Alternate></Puzzle7Alternate>
    },
    8: {
        name: 'page_event',
        component: <Puzzle6Event flagQuota={4} screenTickDecrement={1710} movementDelayDefault={1710}></Puzzle6Event>
    },
    9: {
        name: 'page_event',
        component: <Puzzle2Alternate squares={20} text='Please...'></Puzzle2Alternate>
    },
    10: {
        name: 'page_event',
        component: <Puzzle5Alternate></Puzzle5Alternate>
    },
}

function Puzzle8() {
    let badgeId = 8;

    return (
        <EventParser time={315000} badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle8;