import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import useInterval from '../hooks/useInterval';
import { EventContext } from '../context/EventContext';
import { getNextBadge, setBadgeCompleted } from '../adapters/badges';
import { getLevelLinkFromBadgeId } from '../adapters/level';

import CompletionScreen from './CompletionScreen';

// Events
import WaitEvent from './Events/WaitEvent/WaitEvent';

function EventParser(props) {
    const [eventNumber, setEventNumber] = useState(0);
    const [event, setEvent] = useState(0);
    const [puzzleCompleted, setPuzzleCompleted] = useState(false);
    const [time, setTime] = useState(props.time);
    const [shouldDisplayEvent, setShouldDisplayEvent] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (getNextBadge() < props.badgeId) {
            history.push(getLevelLinkFromBadgeId(getNextBadge()));
        } else {
            setShouldDisplayEvent(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => {
        if (props.time) {
            setTime(time - 1000);
        }

        if (time <= 0) {
            sendPuzzleFailed();
            setTime(props.time);
        }
    }, 1000);

    useEffect(() => {
        setEvent(getEventComponent());
    }, [eventNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    const getEventComponent = () => {
        let event = props.events[eventNumber];
        let component = null;

        switch (event.name) {
            case 'page_event':
                component = (
                    <EventContext.Provider value={setEventCompleted}>
                        {event.component}
                    </EventContext.Provider>
                );
                break;
            case 'wait_event':
                component = <WaitEvent extraText={event.extraText} time={event.time} setEventCompleted={setEventCompleted}></WaitEvent>
                break;
            default:
                break;
        }


        return component;
    }

    const setEventCompleted = () => {
        let eventsLength = Object.keys(props.events).length;

        if ((eventNumber + 1) >= eventsLength) {
            sendPuzzleComplete();
        } else {
            setEventNumber(eventNumber + 1)
        }
    }

    const sendPuzzleComplete = () => {
        setBadgeCompleted(props.badgeId);
        setPuzzleCompleted(true);
    }

    const sendPuzzleFailed = () => {
        setEventNumber(0);
    }

    const displayTime = () => {
        if (props.time) {
            return (
                <div className='absolute bottom-0'>
                    <p className='p-4 text-2xl md:text-6xl text-blue-800 select-none'>{time / 1000 + 's'}</p>
                </div>
            );
        }
    }

    const gameWrapper = () => {
        if (shouldDisplayEvent) {
            return (
                <div className='h-screen'>
                    {puzzleCompleted &&
                        <CompletionScreen></CompletionScreen>
                    }

                    {event}

                    {displayTime()}
                </div>
            )
        } else {
            return <div></div>
        }
    }

    return gameWrapper();
}

export default EventParser;