import React, {useEffect, useState, useContext} from 'react';
import { getTimesRefreshed, incrementRefreshedAmount } from '../../adapters/events';
import { EventContext } from '../../context/EventContext';

let messages = {
    0: {
        timesRefreshedNeeded: 2,
        text: 'Right, I suppose you want a goodbye. Well, thanks so much for playing the game. You exceeded my expectations by making it to the end.'
    },
    1: {
        timesRefreshedNeeded: 3,
        text: 'Okay, really it\'s time to go. Seriously, it\'s over.'
    },
    2: {
        timesRefreshedNeeded: 5,
        text: 'You probably think this is one of those things in a game where if you annoy the NPC enough times they\'ll tell you something interesting or let you progress. THIS. IS. NOT. ONE. OF. THOSE. TIMES. The test is over. LEAVE.'
    },
    3: {
        timesRefreshedNeeded: 8,
        text: 'Fine. If you\'re not going to leave, I will. There are plenty of other test takers to keep company anyway. And by the way, you were the SLOWEST person to make it to the end of the test by my calculations.'
    },
    4: {
        timesRefreshedNeeded: 13,
        text: 'You\'re not going to give up are you? Even if the developer programmed this to take 1000 refreshes, you\'re too persistent to leave, aren\'t you? Well it does take 1000 refreshes, so have fun.'
    },
    5: {
        timesRefreshedNeeded: 18
    }
}

function Puzzle4Event() {
    let [timesRefreshed, updateTimesRefreshed] = useState(0);
    const setEventCompleted = useContext(EventContext);

    useEffect(() => {
        incrementRefreshedAmount();
        updateTimesRefreshed(getTimesRefreshed());
    }, []); 

    useEffect(() => {
        if (timesRefreshed > 18) {
            sendSuccess();
        }
    }, [timesRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const displayMessages = () => {
        let messagesContainer = []  ;

        for (let i = 0; i <= Object.keys(messages).length; ++i) {
            let message = messages[i];

            if (timesRefreshed >= message.timesRefreshedNeeded && message.text) {
                messagesContainer.push(<p className='mb-4'>{message.text}</p>)
            } else {
                break;
            }
        }

        return messagesContainer;
    }

    return (
        <div className='flex h-screen px-8 py-4'>
            <div className='m-auto max-w-screen-sm'>
                <p className='mb-4'>
                    That last one was pretty easy for you, huh?
                    Well listen, you've progressed far and made it to the end of the test. Congratulation! And do not come back.
                </p>

                {displayMessages()}
            </div>
        </div>
    );
}

export default Puzzle4Event;