import React, {useEffect, useState, useContext} from 'react';
import { EventContext } from '../../context/EventContext';


import Modal from 'react-modal';

function Puzzle5Event() {
    let elevatorStatusDefault = {
        maxResidents: 3,
        currentResidents: 0,
        floors: 4,
        currentFloor: 1,
        
        floorResidents: {
            1: 2,
            2: 4,
            3: 3,
            4: 1
        }
    }

    let floorTransitionPeriod = 1400;

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [elevatorStatus, setElevatorStatus] = useState(elevatorStatusDefault);
    const [bgYPosition, _setBgYPosition] = useState(0);

    const setEventCompleted = useContext(EventContext);

    useEffect(() => {
        for (let i = 1; i <= Object.keys(elevatorStatus.floorResidents).length; ++i) {
            if (elevatorStatus.floorResidents[i] !== i) {
                break;
            } else {
                if (i === elevatorStatusDefault.floors) {
                    sendSuccess();
                }
            }
        }
    }, [elevatorStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const reset = () => {
        setElevatorStatus(elevatorStatusDefault);
    }

    const openFloorModule = () => {
        setModalIsOpen(true);
    }

    const closeFloorModule = () => {
        setModalIsOpen(false);
    }

    const goToFloor = (toFloor) => {
        closeFloorModule();

        if (toFloor === elevatorStatus.currentFloor) {
            return;
        }

        if (toFloor > elevatorStatus.currentFloor) {
            handleBgTransition('up', () => {
                setCurrentFloor(toFloor);
            });
        }

        if (toFloor < elevatorStatus.currentFloor) {
            handleBgTransition('down', () => {
                setCurrentFloor(toFloor);
            });
        }
    }

    const setCurrentFloor = (toFloor) => {
        let _elevatorStatus = {...elevatorStatus};
        _elevatorStatus.currentFloor = toFloor;

        setElevatorStatus(_elevatorStatus);
    }

    const handleBgTransition = (direction, next) => {
        let velocity;

        switch (direction) {
            case 'up':
                velocity = 1500;
                break;
            case 'down':
                velocity = -1500;
                break;
            default:
                break;

        }

        _setBgYPosition(bgYPosition + velocity);

        setTimeout(() => {
            next();
        }, floorTransitionPeriod);
    }

    const takeResidents = () => {
        let _elevatorStatus = {...elevatorStatus};
        let currentResidents = _elevatorStatus.currentResidents;
        let floorResidents = _elevatorStatus.floorResidents[elevatorStatus.currentFloor];

        let availableCapacity = _elevatorStatus.maxResidents - currentResidents;

        if (floorResidents > 4) {
            floorResidents = 4;
        }

        if (floorResidents > 0) {
            let residentsToTake = 0;

            if (availableCapacity < floorResidents) {
                residentsToTake = availableCapacity;
            } else {
                residentsToTake = floorResidents;
            }

            _elevatorStatus.floorResidents[elevatorStatus.currentFloor] -= residentsToTake;
            _elevatorStatus.currentResidents += residentsToTake;

            setElevatorStatus(_elevatorStatus);
        }
    }

    const dropOffResidents = () => {
        let _elevatorStatus = {...elevatorStatus};

        _elevatorStatus.floorResidents[elevatorStatus.currentFloor] += _elevatorStatus.currentResidents;
        _elevatorStatus.currentResidents = 0;

        setElevatorStatus(_elevatorStatus);
    }

    const displayFloor = () => {
        let numResidents = elevatorStatus.floorResidents[elevatorStatus.currentFloor];

        let button = null;

        if (elevatorStatus.currentResidents <= 0) {
            button = <button onClick={takeResidents} className='btn btn-black text-white'>Take Residents</button>;
        } else {
            button = <button onClick={dropOffResidents} className='btn btn-black text-white'>Drop Off Residents</button>
        }
        
        return (
            <div className='bg-white mx-3 px-5 py-4'>
                <div className='pb-2 mb-2 border-b-2'>
                    <p className='font-bold'>{elevatorStatus.currentResidents === 0 ? 'Elevator Empty (0)' : 'Elevator Capacity (' + elevatorStatus.currentResidents + ')'}</p>
                </div>

                <h1>You are on floor {elevatorStatus.currentFloor}.</h1>
                <p>
                    There {numResidents === 1 ? 'is' : 'are'} {numResidents} floor resident{numResidents > 1 ? 's' : ''} here. {numResidents !== elevatorStatus.currentFloor ? '❌' : '✅'}
                </p>
                <div className='flex gap-3 mt-5'>
                    {button}
                    <button onClick={openFloorModule} className='btn btn-black text-white'>Change Floors</button>
                    <button onClick={reset} className='btn btn-red text-white'>Reset Test</button>
                </div>
            </div>
        )
    }

    const displayFloorModule = () => {
        let floorModule = [];

        for (let i = 1; i <= elevatorStatusDefault.floors; ++i) {
            if (i === elevatorStatus.currentFloor) {
                continue;
            }

            floorModule.push(
                <button onClick={() => goToFloor(i)} className='btn btn-black text-white'>{i}</button>
            );
        }

        return (
            <div className='bg-white mx-3 px-5 py-4'>
                <p>Which floor will you go to?</p>

                <div className='flex gap-3 mt-5'>
                    {floorModule}
                    <button onClick={closeFloorModule} className='btn btn-black text-white'>Cancel</button>
                </div>
            </div>
        )
    }

    return (
        <div style={{backgroundPositionY: bgYPosition, transitionTimingFunction: 'ease-in-out', transitionDuration: floorTransitionPeriod + 'ms'}} className='flex h-screen bg-squares bg-black bg-opacity-90'>
            <div id='elevator' className='h-screen flex justify-center items-center w-full'>
                {displayFloor()}
            </div>

            <Modal className='max-w-screen-md center-transform' isOpen={modalIsOpen}>
                {displayFloorModule()}
            </Modal>
        </div>
    );
}

export default Puzzle5Event;