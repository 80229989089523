import React from 'react';
import Storyboard from '../Utility/Storyboard';

function Puzzle9Event() {
    return (
        <div className='h-screen flex items-center justify-center p-6'>
            <Storyboard link='/'>
                <div>
                    <p className='text-3xl md:text-5xl'>You've conquered the impossible.</p>
                </div>

                <div>
                    <p>Not many people make it this far, but I knew <span class='text-red-600'>you</span> could do it, <br></br>because <span class='text-red-600'>you</span> can do anything.</p>
                </div>

                <div>
                    <p className='text-xl font-bold'>Thanks for playing.</p>
                    <p>This is very truly the end, I promise.</p>
                     
                    <p className='mt-5'><br></br>Probably. <br></br>Don't mind the home screen telling you to continue the test<br></br> when you go back to it, there's nothing more (seriously).</p>
                </div>
            </Storyboard>
        </div>
    );
}

export default Puzzle9Event;