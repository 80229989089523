import React, {useEffect, useContext} from 'react';
import { EventContext } from '../../context/EventContext';

function Puzzle8Event() {
    const setEventCompleted = useContext(EventContext);

    const sendSuccess = () => {
        setEventCompleted();
    }

    useEffect(() => {
        setTimeout(() => {
            sendSuccess();
        }, 5300);
    });

    return (
        <div className='h-screen flex items-center justify-center'>
            <div>
                <p className='animate-fadeout'>No more games.</p>
            </div>
        </div>
    );
}

export default Puzzle8Event;