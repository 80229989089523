import React, {useState} from 'react';
import {Link} from "react-router-dom";


function Storyboard(props) {
    const [marker, setMarker] = useState(0);
    
    const incrementMarker = () => {
        setMarker(marker + 1);
    }

    const displayChildren = () => {
        let childrenLength = Object.keys(props.children).length - 1;
        let button = null;

        if (marker < childrenLength) {
            button = <button onClick={incrementMarker} className='btn btn-black text-white mt-3'>Next</button>
        } else {
            button = <Link to={props.link}><button className='btn btn-black-border mt-3'>Continue</button></Link>
        }

        return (
            <div>
                {props.children[marker]}
                {button}
            </div>
        );
    }

    return displayChildren();
}

export default Storyboard;