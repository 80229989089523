import React from 'react';
import Puzzle9Event from '../../components/PageEvents/Puzzle9Event';

import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'page_event',
        component: <Puzzle9Event></Puzzle9Event>
    }
}

function Puzzle8() {
    let badgeId = 9;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle8;