import React, {useState, useContext} from 'react';
import { EventContext } from '../../context/EventContext';

import Modal from 'react-modal';

// Helpers
import {hasEveryCharacterInAlphabet} from '../../helpers/String';

let _formStatus = {
    data: {
        name: null,
        email: null,
        description: null
    },

    error: {
        message: null,
        showDescriptionPolicy: false
    },
}

function Puzzle3Event() {
    const setEventCompleted = useContext(EventContext);
    
    const [modalIsOpen, setIsOpen] = useState({
        reportFormIsOpen: false,
        descriptionPolicyIsOpen: false
    });

    const [formStatus, setFormStatus] = useState(_formStatus);

    const sendSuccess = () => {
        setEventCompleted();
    }

    const openReportForm = () => {
        let _modalIsOpen = {...modalIsOpen};
        _modalIsOpen.reportFormIsOpen = true;

        setIsOpen(_modalIsOpen);
    }

    const openDescriptionPolicy = () => {
        let _modalIsOpen = {...modalIsOpen};
        _modalIsOpen.descriptionPolicyIsOpen = true;

        setIsOpen(_modalIsOpen);
    }

    const closeDescriptionPolicy = () => {
        let _modalIsOpen = {...modalIsOpen};
        _modalIsOpen.descriptionPolicyIsOpen = false;

        setIsOpen(_modalIsOpen);
    }

    const validateFormSubmission = (e) => {
        e.preventDefault();
        let submission = {
            name: e.target[0].value,
            email: e.target[1].value,
            description: e.target[2].value
        }
        

        if (!submission.name) {
            setFormError('Please fill out your name to continue submission.');
            return;
        }
        
        if (!submission.email) {
            setFormError('Please fill out your email address so we can respond to your submission.');
            return;
        }

        if (!submission.description) {
            setFormError('Please make sure to provide a description of the problem.');
            return;
        }

        /* Name checks */
        if (submission.name.charAt(0) !== submission.name.charAt(0).toUpperCase()) {
            setFormError('Please ensure your name begins with a capital letter.');
            return;
        }

        if (!submission.name.includes(' ')) {
            setFormError('Please input your name twice in the system (with a space inserted in between each name) in the Name field. We use your name twice for our program. Thank you.');
            return;
        }

        if (submission.name.length >= 7) {
            setFormError('Please consider having and using a shorter name. Thank you.');
            return;
        }

        if (submission.name.length < 6) {
            setFormError('Your name is too short.');
            return;
        }

        /* Email checks */
        if (!submission.email.endsWith('.com') && !submission.email.endsWith('.org')) {
            setFormError('Please ensure your email address ends in ".com" or ".org".');
            return;
        }

        if (submission.email.includes('1') || submission.email.includes('e') || submission.email.includes('a')) {
            setFormError('Due to our security policy, we cannot accept email addresses that contain the characters "1", "e", or "a". Please create a new email address and resubmit the form. Thank you.');
            return;
        }

        if (!submission.email.includes('@') || submission.email.includes('@.com') || submission.email.includes('@.org')) {
            setFormError('That does not appear to be a valid email address. Are you missing something?');
            return;
        }

        /* Description checks */
        if (submission.description.length < 70 || !submission.description.includes(351) || !hasEveryCharacterInAlphabet(submission.description) || submission.description.length > 100) {
            setFormError('Your description does not follow our description policy. Please read our policy below.', true);
            return;
        } else {
            sendSuccess();
        }
    }

    const setFormError = (error, showDescriptionPolicy) => {
        let _formStatus = {...formStatus};
        _formStatus.error.message = error;
        _formStatus.error.showDescriptionPolicy = showDescriptionPolicy;

        setFormStatus(_formStatus);
    }

    const displayError = () => {
        if (formStatus.error.message) {
            let extra = null;

            if (formStatus.error.showDescriptionPolicy) {
                extra = <button className='btn btn-white text-red-500 mt-3' onClick={openDescriptionPolicy}>Open Description Policy</button>
            }
            
            return (
                <div className='bg-red-600 mt-3 py-2 px-3'>
                    <p className='text-white text-sm'>{formStatus.error.message}</p>
                    {extra}
                </div>
            );
        }
    }

    return (
        <div className='p-4'>
            <p>Warning: include(puzzle-3.php): failed to open stream: No such file or directory in /root/msft/home/webpuzzles/index.php on line <b>351</b></p>
            <button onClick={openReportForm} className='btn-classic text-sm p-1 mt-1'>Send Error Report</button>

            <Modal className='reportForm w-72 md:w-auto bg-green-500 max-w-full py-4 px-5 center-transform rounded' isOpen={modalIsOpen.reportFormIsOpen}>
                <div>
                    <h1 className='text-2xl text-white'>Error Report Form</h1>
                    <p className='text-white text-sm mt-1'>Encountered an error? Please leave a message with your name, email, and details of the error and we will get back to you as soon as possible!</p>
                    <form onSubmit={(e) => validateFormSubmission(e)} className='grid grid-cols gap-3 mt-5'>
                        <input className='px-2 py-2 col-span-2 md:col-span-1' type='text' name='fname' placeholder='Your Name' />
                        <input className='px-2 py-2 col-span-2 md:col-span-1' type='text' name='email' placeholder='Email Address' />

                        <textarea className='px-2 py-2 col-span-2' name='description' placeholder='What is the issue?'></textarea>

                        <input className='py-2 col-span-2 btn btn-white text-green-500 font-bold' type='submit' value='Submit' />
                    </form>

                    {displayError()}
                </div>
            </Modal>

            <Modal className='bg-red-600 w-72 md:w-auto max-w-full py-4 px-5 center-transform rounded' isOpen={modalIsOpen.descriptionPolicyIsOpen}>
                <div className='text-white'>
                    <h1 className='text-2xl'>Our Description Policy</h1>
                    <p className='text-sm mt-1'>Please follow all the rules of our description to ensure the fastest and most effective response.</p>
                    <ol className='text-sm mt-3 mb-3'>
                        <li>Thou shalt not include below 70 characters.</li>
                        <li>Thou shalt include the line number that the error occurred on.</li>
                        <li>Thou shalt include at least one of each character in the English alphabet.</li>
                        <li>Thou shalt not include above 100 characters.</li>
                    </ol>
                    <button onClick={closeDescriptionPolicy} className='btn btn-white text-red-600 mt-3'>Close</button>
                </div>
            </Modal>
        </div>
    );
}

export default Puzzle3Event;