import React from 'react';
import Puzzle7Event from '../../components/PageEvents/Puzzle7Event';
import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'page_event',
        component: <Puzzle7Event></Puzzle7Event>
    },
}

function Puzzle7() {
    let badgeId = 7;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle7;