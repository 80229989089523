import React from 'react';

import Puzzle2Event from '../../components/PageEvents/Puzzle2Event';
import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'wait_event',
        time: 15000,
        extraText: "It's probably your fault anyway."
    },
    1: {
        name: 'page_event',
        component: <Puzzle2Event></Puzzle2Event>
    },
    2: {
        name: 'wait_event',
        time: 5000
    }
}

function Puzzle2() {
    let badgeId = 2;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle2;