import React from 'react';

import Puzzle5Event from '../../components/PageEvents/Puzzle5Event';
import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'page_event',
        component: <Puzzle5Event></Puzzle5Event>
    },
}

function Puzzle5() {
    let badgeId = 5;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle5;