import React, {useEffect, useState, useContext} from 'react';
import { EventContext } from '../../context/EventContext';
import { getRandomInt } from '../../helpers/Math';

function Puzzle1Event(props) {
    const setEventCompleted = useContext(EventContext);
    const [textToType, setTextToType] = useState('your name in the box below.');

    useEffect(() => {
        if (props.version === 'alternate') {
            let number = getRandomInt(props.min, props.max).toString();
            setTextToType(number);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const handleNameChange = (e) => {
        if (props.version === 'alternate') {
            if (e.target.value === textToType.split('').reverse().join('')) {
                sendSuccess();
            }
        } else {
            if (e.target.value === textToType || e.target.value === 'your name') {
                sendSuccess();
            }
        }
    }

    const getGameText = () => {
        let text;
        
        switch (props.version) {
            case 'alternate':
                text = textToType;
                break;
            default:
                text = 'For this first problem, we can proceed after you type your name in the box below.';
                break;
        }

        return text;
    }

    return (
        <div className='flex h-screen px-8'>
            <div className='m-auto max-w-screen-sm'>
                <p>
                    {getGameText()}
                </p>
                
                <input className='border-2 px-2 py-1 border-black mt-3' onChange={(e) => handleNameChange(e)} placeholder={props.version !== 'alternate' ? 'Input' : 'tupnI'} name="name" type="text"></input>

                <p className='mt-8 italic text-sm'>
                    Our data shows that about 60% of our participants are unable to pass this portion of the test. With that, I shall call it the Great Divide.
                    I do hope to see you on the other side of the divide.
                </p>
            </div>
        </div>
    );
}

export default Puzzle1Event;