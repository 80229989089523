import React, {useEffect, useState, useContext} from 'react';
import { EventContext } from '../../../context/EventContext';

import { shuffleArray } from '../../../helpers/Array';

function Puzzle2Alternate(props) {
    const [lastClick, setLastClick] = useState(0);
    const [squares, setSquares] = useState([]);

    const setEventCompleted = useContext(EventContext);

    useEffect(() => {
        createSquares();

        if (lastClick >= props.squares) {
            sendSuccess();
        }
    }, [lastClick]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const handleSquareClick = (clickedNumber) => {
        if (lastClick < props.squares) {
            if (clickedNumber === (lastClick + 1)) {
                setLastClick(clickedNumber);
            } else {
                setLastClick(0);
            }
        }
    }

    const createSquares = () => {
        let _squares = [];

        for (let i = 1; i <= props.squares; ++i) {
            let extraClasses;
            
            if (i <= lastClick) {
                extraClasses = 'bg-green-500';
            } else {
                extraClasses = 'bg-gray-600';
            }

            _squares.push(
                <div key={i} onClick={() => handleSquareClick(i)} className={'square cursor-pointer p-8 ' + extraClasses}>
                    <h2 className='select-none'>{i}</h2>
                </div>
            )
        }

        shuffleArray(_squares);
        setSquares(_squares);
    }

    return (
        <div className='flex h-screen px-8'>
            <div className='m-auto max-w-screen-sm'>
                <p className='text-red-600'>{props.text}</p>

                <div className='shapes-container grid grid-cols-4 gap-2 mt-8 mb-8 text-center text-white text-xl'>
                    {squares}
                </div>
            </div>
        </div>
    );
}

export default Puzzle2Alternate;