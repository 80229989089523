import React from 'react';
import { getNextBadge } from '../adapters/badges';
import { getLevelLinkFromBadgeId } from '../adapters/level';

import {Link} from "react-router-dom";

// Assets
import puzzleCompleteThumb from '../assets/images/thumbs-up.svg';

function CompletionScreen(props) {
    let nextBadgeId = getNextBadge();
    
    return (
        <div className='flex h-screen modal'>
            <div className='m-auto max-w-screen-sm'>
                <img alt='' className='m-auto w-40 animate-flyin' src={puzzleCompleteThumb}></img>
                <button className='btn btn-green mt-16'><Link to={getLevelLinkFromBadgeId(nextBadgeId)}>Go To Next Test</Link></button>
            </div>
        </div>
    );
}

export default CompletionScreen;