import React from 'react';
import {useHistory} from "react-router-dom";

import { checkHasBadges, getNextBadge } from '../../adapters/badges';
import { getLevelLinkFromBadgeId } from '../../adapters/level';

import Storyboard from '../../components/Utility/Storyboard';


function Home() {
    let history = useHistory();

    const resumeTest = () => {
        if (checkHasBadges) {
            let nextBadgeId = getNextBadge();

            history.push(getLevelLinkFromBadgeId(nextBadgeId));
        }
    }
    
    const displayHomeContainer = () => {
        let container = null;
  
        if (checkHasBadges()) {
            container = (
                <div>
                    <p>Welcome back. Shall we continue with the test?</p>
                    <button onClick={() => resumeTest()} className='btn btn-black text-white mt-3'>Resume Test</button>
                </div>
            );
        } else {
            container = <Storyboard link='/1'>
                            <div>
                                <p>I have been waiting for you.</p>
                                <p>Let me explain, you have been chosen to take <span className='text-red-600'>this test</span> by someone else.</p>
                            </div>

                            <div>
                                <p className='mt-3'>
                                    The test is a collection of simple problems that <span className='text-red-600'>any human</span> should be able to solve.
                                    All you have to do is solve all of the problems and make it to the end. Remember, <span className='text-red-600'>cheating</span> is a form of giving up.
                                </p>

                                <p className='mt-3'>
                                    Got it? Then let's get started.
                                </p>
                            </div>
                        </Storyboard>
        }
  
        return container;
    }
  
    return (
      <div className='flex h-screen justify-center items-center px-5 py-4 max-w-screen-md m-auto'>
          {displayHomeContainer()}
      </div>
    );
}

export default Home;