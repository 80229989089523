import React from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import Home from "../pages/Home/Home";
import Puzzle1 from "../pages/Puzzles/Puzzle1";
import Puzzle2 from "../pages/Puzzles/Puzzle2";
import Puzzle3 from "../pages/Puzzles/Puzzle3";
import Puzzle4 from "../pages/Puzzles/Puzzle4";
import Puzzle5 from "../pages/Puzzles/Puzzle5";
import Puzzle6 from "../pages/Puzzles/Puzzle6";
import Puzzle7 from "../pages/Puzzles/Puzzle7";
import Puzzle8 from "../pages/Puzzles/Puzzle8";
import Puzzle9 from "../pages/Puzzles/Puzzle9";

function EventRouter() {
  return (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/1" component={Puzzle1} />
            <Route path="/2" component={Puzzle2} />
            <Route path="/3" component={Puzzle3} />
            <Route path="/4" component={Puzzle4} />
            <Route path="/5" component={Puzzle5} />
            <Route path="/6" component={Puzzle6} />
            <Route path="/7" component={Puzzle7} />
            <Route path="/8" component={Puzzle8} />
            <Route path="/9" component={Puzzle9} />
        </Switch>
    </BrowserRouter>
  );
}

export default EventRouter;
