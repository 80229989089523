import React from 'react';

import Puzzle4Event from '../../components/PageEvents/Puzzle4Event';
import EventParser from '../../components/EventParser';

let events = {
    0: {
        name: 'page_event',
        component: <Puzzle4Event></Puzzle4Event>
    },
}

function Puzzle4() {
    let badgeId = 4;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle4;