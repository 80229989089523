import React, {useEffect, useState, useContext} from 'react';
import { EventContext } from '../../../context/EventContext';

import { getRandomInt } from '../../../helpers/Math';


function Puzzle7Alternate() {
    const setEventCompleted = useContext(EventContext);
    const [shownNumbers, setShownNumbers] = useState([]);
    const [currentNumber, setCurrentNumber] = useState(null);
    const [answersStatus, setAnswersStatus] = useState({
        numCorrect: 0,
        numIncorrect: 0
    });

    const [gameStatus, setGameStatus] = useState({
        step: 'start',
    });

    useEffect(() => {
        initGameRound();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const restartGame = () => {
        setShownNumbers([]);
        setAnswersStatus({
            numCorrect: 0,
            numIncorrect: 0
        });


        startGameRound();
    }

    const getAndSetRoundNumber = () => {
        let number = getRandomInt(10, 30);
        setCurrentNumber(number);
    }

    const incrementNumCorrect = () => {
        let _answersStatus = {...answersStatus};
        _answersStatus.numCorrect += 1;

        setAnswersStatus(_answersStatus);

    }

    const incrementNumIncorrect = () => {
        let _answersStatus = {...answersStatus};
        _answersStatus.numIncorrect += 1;

        setAnswersStatus(_answersStatus);
    }

    const initGameRound = () => {
        getAndSetRoundNumber();

        _setGameStatus('start');
    }

    const startGameRound = () => {
        getAndSetRoundNumber();
    }

    const _setGameStatus = (newStatus) => {
        let _gameStatus = {...gameStatus};
        _gameStatus.step = newStatus;
        setGameStatus(_gameStatus);
    }

    const checkAnswer = (answer) => {
        let correct = false;
        
        if (shownNumbers.includes(currentNumber)) {
            if (answer === 'yes') {
                correct = true
            } else if (answer === 'no') {
                correct = false;
            }
        } else {
            if (answer === 'yes') {
                correct = false;
            } else if (answer === 'no') {
                correct = true;
            }
        }

        if (correct) {
            if (answersStatus.numCorrect + 1 >= 14) {
                sendSuccess();
                return;
            }

            incrementNumCorrect();
        } else {
            if (answersStatus.numIncorrect + 1 >= 1) {
                restartGame();
                return;
            } else {
                incrementNumIncorrect();
            }
            
        }

        if (!shownNumbers.includes(currentNumber)) {
            let _shownNumbers = shownNumbers.splice(0);
            _shownNumbers.push(currentNumber);
            setShownNumbers(_shownNumbers);
        }

        startGameRound();
    }

    const gameDisplay = () => {
        let component = null;

        switch (gameStatus.step) {
            case 'start': 
                component = (
                    <div id='game' className='text-center'>
                        <p>The number is</p>
                        <p className='mb-4 mt-4 text-2xl font-bold'>{currentNumber}</p>

                        <p>Have I shown you this number before?</p>

                        <div className='mt-5 flex gap-5 justify-center'>
                            <button onClick={() => checkAnswer('yes')} className='btn-black-border btn'>Yes</button>
                            <button onClick={() => checkAnswer('no')} className='btn-black-border btn'>No</button>
                        </div>

                        <div className='mt-5'>
                            <p><span className='text-green-600'>{answersStatus.numCorrect}</span> / 14</p>
                        </div>
                    </div>
                );
                break;
            default:
                break;
        }


        return component;
    }

    return (
        <div className='h-screen flex items-center justify-center m-auto max-w-screen-sm px-8 py-6'>
            {gameDisplay()}
        </div>
    );
}

export default Puzzle7Alternate;