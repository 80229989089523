import React, {useEffect} from 'react';
import './WaitEvent.css';


function WaitEvent(props) {
    let timeElapsed = 0;

    useEffect(() => {
        let timer = setInterval(() => {
            timeElapsed += 1000; // eslint-disable-line react-hooks/exhaustive-deps

            if (timeElapsed >= props.time || !props.time) {
                clearInterval(timer);
                sendSuccess();
            }
        }, 1000);
    }, []);

    const sendSuccess = () => {
        props.setEventCompleted();
    }

    return (
        <div className='modal-overlay flex px-7'>
            <div className='self-center m-auto text-center text-white text-lg'>
                <div className='lds-dual-ring'></div>
                <h2>
                    Due to unusual server load times, you're going to have to wait for a few minutes (or hours).
                </h2>
                <h2 className='mb-2'>
                    If you are inconvenienced by this, don't be, there's nothing I can do!
                </h2>
                {props.extraText &&
                    <h2>{props.extraText}</h2>
                }
            </div>
        </div>
    );
}

export default WaitEvent;