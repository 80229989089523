import React from 'react';
import './App.css';

import EventRouter from './components/EventRouter';

function App() {
  return (
    <div className="App">
      <EventRouter></EventRouter>
    </div>
  );
}

export default App;
