
export const setBadgeCompleted = (badgeId) => {
    let badges = getBadges();

    if (badges[badgeId] != null) {
        badges[badgeId] = true;
    }

    window.localStorage.setItem('badges', JSON.stringify(badges));
}

export const getBadges = () => {
    initializeBadgesIfNotExists();

    return JSON.parse(window.localStorage.getItem('badges'));
}

export const getNextBadge = () => {
    let badges = getBadges();

    for (let i = 1; i < Object.keys(badges).length; ++i) {
        if (badges[i] === false) {
            return i;
        };
    }
}

export const checkHasBadges = () => {
    if (!window.localStorage.getItem('badges')) {
        return false;
    }
    
    return true;
}

export const initializeBadgesIfNotExists = () => {
    if (!window.localStorage.getItem('badges')) {
        let badges = {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false,
            13: false,
            14: false,
            15: false,
            16: false
        }

        window.localStorage.setItem('badges', JSON.stringify(badges));
    }
}