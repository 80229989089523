import React from 'react';

import Puzzle3Event from '../../components/PageEvents/Puzzle3Event';
import EventParser from '../../components/EventParser';


let events = {
    0: {
        name: 'page_event',
        component: <Puzzle3Event></Puzzle3Event>
    },
}

function Puzzle3() {
    let badgeId = 3;

    return (
        <EventParser badgeId={badgeId} events={events}></EventParser>
    );
}

export default Puzzle3;