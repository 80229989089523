export const hasEveryCharacterInAlphabet = (string) => {
    let alphabet = ('abcdefghijklmnopqrstuvwxyz');
    
    for (let i = 0; i < alphabet.length; ++i) {
        console.log(alphabet[i]);
        if (!string.includes(alphabet[i])) {
            return false;
        }
    }

    return true;
}