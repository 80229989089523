import React, {useEffect, useState, useContext} from 'react';
import { EventContext } from '../../context/EventContext';

import { shuffleArray } from '../../helpers/Array';
import useInterval from '../../hooks/useInterval';

function Puzzle2Event() {
    const [lastClick, setLastClick] = useState(0);
    const [squares, setSquares] = useState([]);
    const [timeLeft, setTimeLeft] = useState(7000);
    const [timerIsStarted, setTimerIsStarted] = useState(false);

    const setEventCompleted = useContext(EventContext);

    useInterval(() => {
        if (timerIsStarted) {
            setTimeLeft(timeLeft - 1000);
        }

        if (timeLeft <= 0) {
            setTimerIsStarted(false);
            setTimeLeft(7000);
            setLastClick(0);
        }
    }, 1000);

    useEffect(() => {
        createSquares();

        if (lastClick === 1) {
            setTimerIsStarted(true);
        }

        if (lastClick >= 9) {
            sendSuccess();
        }
    }, [lastClick]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSuccess = () => {
        setEventCompleted();
    }

    const handleSquareClick = (clickedNumber) => {
        if (lastClick < 9) {
            if (clickedNumber === (lastClick + 1)) {
                setLastClick(clickedNumber);
            } else {
                setLastClick(0);
            }
        }
    }

    const createSquares = () => {
        let _squares = [];

        for (let i = 1; i <= 9; ++i) {
            let extraClasses;
            
            if (i <= lastClick) {
                extraClasses = 'bg-green-500';
            } else {
                extraClasses = 'bg-gray-600';
            }

            _squares.push(
                <div key={i} onClick={() => handleSquareClick(i)} className={'square cursor-pointer p-8 ' + extraClasses}>
                    <h2 className='select-none'>{i}</h2>
                </div>
            )
        }

        shuffleArray(_squares);
        setSquares(_squares);
    }

    return (
        <div className='flex h-screen px-8'>
            <div className='m-auto max-w-screen-sm'>
                <p>
                    Good work on the last puzzle, but that was just for beginners.
                    As you can see, there are nine shapes below.
                </p>

                <p className='mt-5'>
                    I need you to click or tap <span onClick={sendSuccess}>on them in the correct order. You will have 6 seconds starting from the first square you tap.</span>
                </p>

                {timerIsStarted &&
                    <p className='mt-5 text-2xl'>
                        {timeLeft / 1000}s
                    </p>
                }

                <div className='shapes-container grid grid-cols-3 gap-2 mt-8 mb-8 text-center text-white text-xl'>
                    {squares}
                </div>
            </div>
        </div>
    );
}

export default Puzzle2Event;